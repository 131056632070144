// Header.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../logo.png'; 
import "./header.css"
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';

const Header = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsUserLoggedIn(true);
      } else {
        setIsUserLoggedIn(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      navigate('/login'); // navigate back to login after logout
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="header">
      <div className="header-left">
        <Link className="logo" to="/">
          <img src={logo} alt="Company Logo" width="41" height="60" className="d-inline-block align-top" />
        </Link>
      </div>
      <div className="header-right">
      <nav className="navbar navbar-expand-lg navbar-custom">
        <button className="navbar-toggler" type="button" onClick={toggleMenu}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''} ${menuOpen ? 'open' : ''}`}>
          <ul className="navbar-nav ml-auto">
            {isUserLoggedIn ? (
                  <>
                    <li className="nav-item">
                      <Link className="nav-link" to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/account">Manage Account</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" onClick={handleLogout}>Logout</Link>
                    </li>
                  </>
                ) : (
                  <li className="nav-item">
                    <Link className="nav-link" to="/login">Login</Link>
                  </li>
                )}
          </ul>
        </div>
      </nav>
      </div>
    </div>
  );
};

export default Header;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import ContactsList from './ContactsList';
import './dashboard.css';

async function sendReminderMessages(auth) {
  const response = await fetch('https://us-central1-nf0-1-356418.cloudfunctions.net/sendReminders', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uid: auth.currentUser.uid }), // Sending current user's uid
  });

  const data = await response.json();
  return data;
};

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const handleAddContact = () => {
    navigate('/contacts');
  };

  return (
    <div className="dashboard">
      <div className="container">
        <div className="dashboard-header">
          <div className="title">Manage Your Reminders</div>
          <button className="btn-add-contact" onClick={handleAddContact}>Add Person</button>
          {/* Send Reminders Button */}
          <button type="button" className="btn-reminders" 
            onClick={() => sendReminderMessages(auth)}>
              Send Reminder Messages
          </button>

        </div>
        <div className="contact-list">          
          <ContactsList />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

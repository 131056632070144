import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';

// Add the custom hook here
const useFormattedPhoneNumber = (initialValue = '') => {
  const [displayValue, setDisplayValue] = useState(initialValue);
  const [rawValue, setRawValue] = useState(initialValue);

  const handleChange = (event) => {
    const raw = event.target.value.replace(/[^\d]/g, '');
    let formattedValue = '(';
  
    for (let i = 0; i < raw.length; i++) {
      if (i === 3) formattedValue += ') ';
      if (i === 6) formattedValue += '-';
      formattedValue += raw[i];
    }
  
    // Only add '+1' if it's not already present
    if (!raw.startsWith('1')) {
      setRawValue(`+1${raw}`);
    } else {
      setRawValue(`+${raw}`);
    }
  
    setDisplayValue(formattedValue);
  };

  return [displayValue, rawValue, handleChange];
};

async function sendVerificationCode(phoneNumber) {
  const response = await fetch('https://us-central1-nf0-1-356418.cloudfunctions.net/sendVerificationCode', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ phoneNumber }),
  });

  const data = await response.json();
  return data;
};

async function verifyPhoneNumber(phoneNumber, verificationCode) {
  const requestBody = JSON.stringify({ phoneNumber, verificationCode });
  console.log('Request body:', requestBody);
  const response = await fetch('https://us-central1-nf0-1-356418.cloudfunctions.net/verifyPhoneNumber', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: requestBody,
  });

  if (!response.ok) {
    const text = await response.text();
    console.error('Response text:', text);
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

const AccountManagement = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [displayPhoneNumber, rawPhoneNumber, handlePhoneNumberChange] = useFormattedPhoneNumber();
  const navigate = useNavigate();
  const auth = getAuth();
  const [phoneVerificationStatus, setPhoneVerificationStatus] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const handleVerify = async () => {
    const data = await verifyPhoneNumber(rawPhoneNumber, verificationCode);
  
    if (data.status === 'success') {
      setIsVerifying(false);
      setPhoneVerificationStatus('Verified');
      toast.success('Phone number verified successfully!');
  
      // Update Firestore
      const db = getFirestore();
      const userRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userRef, {
        phoneVerificationStatus: 'Verified',
      });
    } else {
      console.error('Phone number verification failed:', data.error);
      toast.error('Phone number verification failed. Please try again.');
    }
  };  

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate]);

  useEffect(() => {
    if (auth.currentUser) {
      fetchData(auth.currentUser.uid);
    }
  }, []);

  const fetchData = async (uid) => {
    const db = getFirestore();
    const docRef = doc(db, 'users', uid);
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {
      setFirstName(docSnap.data().firstName);
      setLastName(docSnap.data().lastName);
      // Strip '1' prefix from phone number before passing it to handlePhoneNumberChange
      const phoneNumber = docSnap.data().phoneNumber || '';
      const phoneNumberWithoutPrefix = phoneNumber.startsWith('+1') ? phoneNumber.substring(2) : phoneNumber;
      handlePhoneNumberChange({ target: { value: phoneNumberWithoutPrefix } });
      setPhoneVerificationStatus(docSnap.data().phoneVerificationStatus || '');
      setIsVerifying(false);
    } else {
      console.log('No such document!');
      toast.error('Error fetching user data');
    }
  };
  

  const handleEdit = (event) => {
    event.stopPropagation(); 
    setIsEditing(true);
  };

  const handleCancel = (event) => {
    event.stopPropagation(); 
    setIsEditing(false);
  };

  const handleSave = async () => {
    if (firstName && lastName && rawPhoneNumber) {
      // Add validation for phone number here
      // For example, check that the raw phone number is 11 digits long (1 for the country code + 10 for the phone number)
      const phoneNumberLength = rawPhoneNumber.replace(/[^\d]/g, '').length;
      if (phoneNumberLength !== 11) {
        toast.error('Phone number must be 11 digits long');
        return;
      }
    
      const db = getFirestore();
      const userRef = doc(db, 'users', auth.currentUser.uid);
  
      // Fetch current user data
      const docSnap = await getDoc(userRef);
  
      if (docSnap.exists()) {
        const currentPhoneNumber = docSnap.data().phoneNumber;
  
        // If the phone number has changed, update phoneVerificationStatus to 'Unverified'
        if (currentPhoneNumber !== rawPhoneNumber) {
          setPhoneVerificationStatus('Unverified');
          await updateDoc(userRef, {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: rawPhoneNumber,
            phoneVerificationStatus: 'Unverified',
          });
        } else {
          await updateDoc(userRef, {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: rawPhoneNumber,
          });
        }
    
        toast.success('Successfully updated user details');
        setIsEditing(false);
      } else {
        console.log('No such document!');
        toast.error('Error fetching user data');
      }
    } else {
      toast.error('Please fill all fields');
    }
  };  

  const handleInitiateVerification = async () => {
    const data = await sendVerificationCode(rawPhoneNumber);
  
    if (data.status === 'success') {
      setIsVerifying(true);
    } else {
      console.error('Phone number verification initiation failed:', data.error);
      toast.error('Phone number verification initiation failed. Please try again.');
    }
  };

  return (
    <div className="account-container">
      <h2>Account Management</h2>
      {phoneVerificationStatus === 'Unverified' && (
          <div className="alert alert-danger" role="alert">
            Your phone number needs to be verified.
          </div>
      )}
      {phoneVerificationStatus === 'Unverified' && !isVerifying && (
        <div className="form-group">
          <button type="button" className="btn btn-warning" onClick={handleInitiateVerification}>Initiate Verification</button>
        </div>
      )}
      {isVerifying && (
        <form>
          <div className="form-group">
            <label htmlFor="verificationCode">Verification Code</label>
            <input type="text" className="form-control" id="verificationCode" 
                  placeholder="Enter verification code" value={verificationCode} 
                  onChange={(e) => setVerificationCode(e.target.value)}
                  onInput={(e) => setVerificationCode(e.target.value)} />
          </div>
          <div className="form-group">
            <button type="button" className="btn btn-primary" onClick={handleVerify}>Verify</button>
          </div>
        </form>
      )}
      
      <form>
        <div className="form-group">
          
          <label htmlFor="firstName">First Name</label>
          <input type="text" className="form-control" id="firstName" 
                 placeholder="Enter first name" value={firstName} 
                 onChange={(e) => setFirstName(e.target.value)} 
                 disabled={!isEditing} />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input type="text" className="form-control" id="lastName" 
                 placeholder="Enter last name" value={lastName} 
                 onChange={(e) => setLastName(e.target.value)} 
                 disabled={!isEditing} />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="tel"
            className="form-control"
            id="phoneNumber"
            placeholder="Enter phone number"
            value={displayPhoneNumber}  // use displayPhoneNumber for display
            onChange={handlePhoneNumberChange}
            disabled={!isEditing}
          />
        </div>
        
        {isEditing ? (
          <div className="form-group">
            <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={(e) => handleSave(e)}>Save</button>
          </div>
        ) : (
          <div className="form-group">
            <button type="button" className="btn btn-primary" onClick={handleEdit}>Edit</button>
          </div>
        )}
      </form>
      
      

    </div>
  );
  
};

export default AccountManagement;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db, auth } from '../firebase';
import './dashboard.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ContactsList = () => {
  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);
  
  const deleteContact = async () => {
    try {
      await deleteDoc(doc(db, "contacts", contactToDelete));
      setContacts(contacts.filter(contact => contact.id !== contactToDelete));
      setShowModal(false);
      toast.success("Contact deleted successfully");
    } catch (error) {
      console.error("Error deleting contact: ", error);
    }
  };

  useEffect(() => {
    const fetchContacts = async () => {
      const user = auth.currentUser;
    
      if (user) {
        const q = query(collection(db, "contacts"), where("userId", "==", user.uid));
    
        const querySnapshot = await getDocs(q);
        let contactsArray = [];
        querySnapshot.forEach((doc) => {
          const contact = doc.data();
          contact.id = doc.id;  // Include the document ID
          contactsArray.push(contact);
        });
    
        // Sort contacts by month and day
        contactsArray.sort((a, b) => {
          return (a.date && b.date) ? ((a.date.month - b.date.month) || (a.date.day - b.date.day)) : 0;
        });
    
        setContacts(contactsArray);
      } else {
        // alert("You must be logged in to see contacts");
        navigate('/login');
      }
    }
    

    fetchContacts();
  }, []);

  const formatDate = (date) => {
    let dateString = '';
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  
    if (date && date.month && date.day) {
      dateString = `${monthNames[date.month - 1]} ${date.day}`;
    }
    if (date && date.year) {
      dateString += `, ${date.year}`;
    }
    return dateString;
  };  

  return (
    
    <div className="contacts-list">
      {contacts.map((contact, index) => (
        <div key={index} className="contact">
          <div className="contact-column">
            <h4 className="contact-name">{contact.name}</h4>
          </div>
          <div className="contact-column">
            <p className="contact-occasion">{contact.occasion}</p>
          </div>
          <div className="contact-column">
            <p className="contact-date">{formatDate(contact.date)}</p>
          </div>
          <div className="contact-column">
            <button className="btn btn-secondary" onClick={() => navigate(`/edit/${contact.id}`)}>Edit</button>
            <button className="btn-delete-contact" onClick={() => {setShowModal(true); setContactToDelete(contact.id);}}>Delete</button>
          </div>      
        </div>
      ))}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this contact?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteContact}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ContactsList;

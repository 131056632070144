// Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import heroImage from '../hero.png'; // Update this path to your actual hero image

const isMobileDevice = () => {
  return window.innerWidth <= 767; // Adjust the breakpoint as needed
};

const Home = () => {
  return (
    <div className="home-container">
      
      <div className="hero">
      {!isMobileDevice() && (
        <div className="hero-image" style={{backgroundImage: `url(${heroImage})`}}></div>
      )}
        <div className="hero-content">
          <p className="hero-subtitle">Ensure you never miss a special day for your loved ones.</p>
          <Link to="/login">
            <button className="login-button">Login</button>
          </Link>
        </div>
      </div>
      
    </div>

  );
};

export default Home;

// Footer.js
import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <p className="footer-text">Stay connected and never miss an occasion. Sign up for our reminders and become a part of the Never Forget family today!</p>
    </div>
  );
};

export default Footer;

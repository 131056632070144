import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import './contact-form.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactEdit = () => {
  const { id } = useParams();
  const { register, handleSubmit, formState: { errors }, setValue, trigger, control, watch } = useForm();
  const navigate = useNavigate();
  const line1Ref = useRef();
  const [contact, setContact] = useState(null);

  const month = watch('month');
  const day = watch('day');
  const year = watch('year');

  // Function to calculate the number of days in a given month
  const daysInMonth = (month, year) => {
    return new Date(year, month - 1, 0).getDate();
  };

  useEffect(() => {
    if (month && year) {
      const maxDay = daysInMonth(month, year);
      if (day > maxDay) {
        setValue('day', maxDay.toString());
      }
    }
  }, [month, year, day, setValue]);

  useEffect(() => {
    const fetchContact = async () => {
      const contactRef = doc(db, 'contacts', id);
      const contactDoc = await getDoc(contactRef);

      if (contactDoc.exists()) {
        setContact(contactDoc.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchContact();
  }, [id]);

  useEffect(() => {
    if (contact) {
      setValue('name', contact.name);
      setValue('occasion', contact.occasion);
      
      if (contact.date) {
        // Convert the separate day, month, and year fields into a yyyy-mm-dd string
        const date = `${contact.date.year}-${contact.date.month.toString().padStart(2, '0')}-${contact.date.day.toString().padStart(2, '0')}`;
        setValue('date', date);
      }
      
      if (contact.address) {
        setValue('line1', contact.address.line1);
        setValue('line2', contact.address.line2);
        setValue('city', contact.address.city);
        setValue('state', contact.address.state);
        setValue('zip', contact.address.zip);
      }
    }
  }, [contact, setValue]);  
  

  useEffect(() => {
    if (!contact) {
      return;
    }
  
    const autocomplete = new window.google.maps.places.Autocomplete(line1Ref.current, {
      types: ['address'],
      componentRestrictions: { country: "us" }
    });
  
    autocomplete.addListener("place_changed", () => {
      line1Ref.current.value = ''; // Clear the input field
    
      const place = autocomplete.getPlace();
    
      const streetNumberComponent = place.address_components.find((component) => component.types.includes("street_number"));
      const routeComponent = place.address_components.find((component) => component.types.includes("route"));
      let cityComponent = place.address_components.find((component) => component.types.includes("locality"));
      const stateComponent = place.address_components.find((component) => component.types.includes("administrative_area_level_1"));
      const zipComponent = place.address_components.find((component) => component.types.includes("postal_code"));
    
      const streetNumber = streetNumberComponent ? streetNumberComponent.long_name : '';
      const route = routeComponent ? routeComponent.long_name : '';
      // If no "locality" is found, look for "sublocality"
      if (!cityComponent) {
        cityComponent = place.address_components.find((component) => component.types.includes("sublocality_level_1"));
      }
      const city = cityComponent ? cityComponent.long_name : '';
      const state = stateComponent ? stateComponent.short_name : '';
      const zip = zipComponent ? zipComponent.short_name : '';
    
      const streetNumName = streetNumber + ' ' + route;
    
      line1Ref.current.value = streetNumName;
      setValue('line1', streetNumName, { shouldValidate: true });
      setValue('city', city);
      setValue('state', state);
      setValue('zip', zip);
    
      trigger(['line1', 'city', 'state', 'zip']); // manually trigger the validation
    });    
  }, [contact]); // Add contact as a dependency

  const onSubmit = async (data) => {
    const user = auth.currentUser;
  
    if (user) {
      data.line1 = line1Ref.current.value; // Manually update line1 with the ref's value
  
      // Extract the year, month, and day directly from the date string
      const [year, month, day] = data.date.split('-');

      const docData = {
        name: data.name,
        occasion: data.occasion,
        date: {
          day: parseInt(day, 10), // Parse the day from the string
          month: parseInt(month, 10), // Parse the month from the string
          year: parseInt(year, 10), // Parse the year from the string
        },
        userId: user.uid,
        address: {
          line1: data.line1,
          line2: data.line2,
          city: data.city,
          state: data.state,
          zip: data.zip
        },
      };
  
      try {
        const contactRef = doc(db, 'contacts', id);
        await updateDoc(contactRef, docData);
        toast.success('Update successful. We won\'t forget that!');
        navigate('/dashboard');
      } catch (e) {
        console.error('Error updating document: ', e);
      }
    } else {
      navigate('/login');
    }
  };
  
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="container mt-5">
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input {...register('name', { required: true })} className="form-control" id="name" placeholder="Enter name" />
        {errors.name && <span className="text-danger">This field is required</span>}
      </div>

      <div className="form-group">
        <label htmlFor="occasion">Occasion</label>
        <select {...register('occasion', { required: true })} className="form-control" id="occasion">
          <option value="">-- Please choose what you don't want to forget --</option>
          <option value="Birthday">Birthday</option>
          <option value="Anniversary">Anniversary</option>
          <option value="Mother's Day">Mother's Day</option>
          <option value="Father's Day">Father's Day</option>
          <option value="Other">Other</option>
        </select>
        {errors.occasion && <span className="text-danger">This field is required</span>}
      </div>
      
      <div className="form-group">
        <label htmlFor="date">Date</label>
        <input
          {...register('date', { required: true })}
          type="date"
          className="form-control"
          id="date"
        />
        {errors.date && <span className="text-danger">This field is required</span>}
      </div>

      <div className="form-group">
        <label htmlFor="line1">Address Line 1</label>
        <input
          {...register('line1', { required: true })}
          defaultValue={contact ? contact.address.line1 : ''}
          className="form-control"
          id="line1"
          placeholder="Address Line 1"
          ref={line1Ref}
        />
        {errors.line1 && <span className="text-danger">This field is required</span>}
      </div>

      <div className="form-group">
        <label htmlFor="line2">Address Line 2</label>
        <input {...register('line2')} className="form-control" id="line2" placeholder="Address Line 2" />
      </div>

      <div className="form-group">
        <label htmlFor="city">City</label>
        <input {...register('city', { required: true })} className="form-control" id="city" placeholder="City" />
        {errors.city && <span className="text-danger">This field is required</span>}
      </div>

      <div className="form-group">
        <label htmlFor="state">State</label>
        <input {...register('state', { required: true })} className="form-control" id="state" placeholder="State" />
        {errors.state && <span className="text-danger">This field is required</span>}
      </div>

      <div className="form-group">
        <label htmlFor="zip">Zip</label>
        <input {...register('zip', { required: true })} className="form-control" id="zip" placeholder="Zip" />
        {errors.zip && <span className="text-danger">This field is required</span>}
      </div>

      <button type="submit" className="btn btn-primary edit-contact-btn">Update</button>
      <button type="button" className="btn btn-danger ml-3" onClick={() => navigate('/dashboard')}>Cancel</button>
    </form>
);
}

export default ContactEdit;


import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Contacts from './components/Contacts';
import Dashboard from './components/Dashboard';
import ContactsList from './components/ContactsList';
import Header from './components/Header';
import Footer from './components/Footer';
import ContactEdit from './components/ContactEdit';
import AccountManagement from './components/AccountManagement';
import 'bootstrap/dist/css/bootstrap.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS styles


function App() {
  return (
    <Router>
      <ToastContainer />
      <RoutesWithHeader />
    </Router>
  );
}

function RoutesWithHeader() {
  const location = useLocation();
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/contacts-list" element={<ContactsList />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/edit/:id" element={<ContactEdit />} />
        <Route path="/account" element={<AccountManagement />} />
      </Routes>
      <Footer />
    </div>
  );
}


export default App;

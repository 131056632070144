import React from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { toast } from 'react-toastify';

const Login = () => {
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  const firestore = getFirestore();
  const navigate = useNavigate();

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // Extract first and last name from displayName
        const displayName = result.user.displayName;
        const uid = result.user.uid;
        let firstName = '';
        let lastName = '';
        if (displayName) {
          const nameParts = displayName.split(' ');
          firstName = nameParts[0];
          lastName = nameParts.length > 1 ? nameParts[1] : '';
        }
  
        // Store user info in Firestore
        const userDoc = doc(firestore, 'users', uid);
        const docSnap = await getDoc(userDoc);
  
        // Only set the doc if it does not already exist
        if (!docSnap.exists()) {
          await setDoc(userDoc, { 
            firstName, 
            lastName, 
            uid, 
            phoneVerificationStatus: 'Unverified' 
          });
        } else if (!docSnap.data().phoneVerificationStatus) {
          // If phoneVerificationStatus does not exist, add it
          await updateDoc(userDoc, { phoneVerificationStatus: 'Unverified' });
        }
  
        console.log(`First Name: ${firstName}, Last Name: ${lastName}`);
        toast.success(`Welcome, ${firstName}!`);
        
        navigate('/dashboard');
      })
      .catch((error) => {
        console.log(error.message);
      });
  };  
  

  return (
    <div className="login-container">
      <h1 className="login-title">Sign In</h1>
      <button className="login-button" onClick={signInWithGoogle}>Sign In With Google</button>
    </div>
  );
};

export default Login;

/*global initializeApp */
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA29_0BoLGln6QN_FuvSSHa09UxkUBzQRs",
  authDomain: "nf0-1-356418.firebaseapp.com",
  databaseURL: "https://nf0-1-356418-default-rtdb.firebaseio.com",
  projectId: "nf0-1-356418",
  storageBucket: "nf0-1-356418.appspot.com",
  messagingSenderId: "109953783267",
  appId: "1:109953783267:web:9df4289668f477c9e93cd6",
  measurementId: "G-8KECFHNMHN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

export { auth, provider, db };
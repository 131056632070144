import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import './contact-form.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contacts = () => {
  const { register, handleSubmit, formState: { errors }, setValue, trigger } = useForm();
  const navigate = useNavigate();
  const line1Ref = useRef();
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');

  // Function to calculate the number of days in a given month
  const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  useEffect(() => {
    if (month !== '' && year !== '') {
      const maxDay = daysInMonth(month, year);
      if (day > maxDay) {
        setDay(maxDay.toString());
      }
    } else if (month !== '' && year === '') {
      const maxDay = daysInMonth(month, new Date().getFullYear());
      if (day > maxDay) {
        setDay(maxDay.toString());
      }
    }
  }, [month, year]);

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(line1Ref.current, {
      types: ['address'],
      componentRestrictions: { country: "us" }
    });
  
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
    
      const streetNumberComponent = place.address_components.find((component) => component.types.includes("street_number"));
      const routeComponent = place.address_components.find((component) => component.types.includes("route"));
      let cityComponent = place.address_components.find((component) => component.types.includes("locality"));
      const stateComponent = place.address_components.find((component) => component.types.includes("administrative_area_level_1"));
      const zipComponent = place.address_components.find((component) => component.types.includes("postal_code"));
    
      const streetNumber = streetNumberComponent ? streetNumberComponent.long_name : '';
      const route = routeComponent ? routeComponent.long_name : '';
      // If no "locality" is found, look for "sublocality"
      if (!cityComponent) {
        cityComponent = place.address_components.find((component) => component.types.includes("sublocality_level_1"));
      }
      const city = cityComponent ? cityComponent.long_name : '';
      const state = stateComponent ? stateComponent.short_name : '';
      const zip = zipComponent ? zipComponent.short_name : '';

      const streetNumName = streetNumber + ' ' + route;
    
      setValue('line1', streetNumName);
      setValue('city', city);
      setValue('state', state);
      setValue('zip', zip);
      
      line1Ref.current.value = streetNumName; // Directly set the value of the input field to the street address
    
      trigger(['line1', 'city', 'state', 'zip']); // manually trigger the validation
    });
  }, []);    

  const onSubmit = async (data) => {
    const user = auth.currentUser;
  
    if (user) {
      data.line1 = line1Ref.current.value; // Manually update line1 with the ref's value
  
      // Extract the year, month, and day directly from the date string
      const [year, month, day] = data.date.split('-');

      const docData = {
        name: data.name,
        occasion: data.occasion,
        date: {
          day: parseInt(day, 10), // Parse the day from the string
          month: parseInt(month, 10), // Parse the month from the string
          year: parseInt(year, 10), // Parse the year from the string
        },
        userId: user.uid,
        address: {
          line1: data.line1,
          line2: data.line2,
          city: data.city,
          state: data.state,
          zip: data.zip
        },
      };
  
      try {
        await addDoc(collection(db, 'contacts'), docData);
        toast.success('Success! We\'ll never forget them!');
        navigate('/dashboard');
      } catch (e) {
        console.error('Error adding document: ', e);
        toast.error('Error updating contact', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } else {
      // alert('You must be logged in to add a contact');
      navigate('/login');
    }
  };
  

return (
  <div className="new-reminder-container">
    <div className="dashboard-header">
    <div className="title">Enter celebration details</div>
    </div>
    <form onSubmit={handleSubmit(onSubmit)} className="container mt-5">
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input {...register('name', { required: true })} className="form-control" id="name" placeholder="Enter name" />
        {errors.name && <span className="text-danger">This field is required</span>}
      </div>

      <div className="form-group">
        <label htmlFor="occasion">Occasion</label>
        <select {...register('occasion', { required: true })} className="form-control" id="occasion">
          <option value="">-- Please choose what you don't want to forget --</option>
          <option value="Birthday">Birthday</option>
          <option value="Anniversary">Anniversary</option>
          <option value="Mother's Day">Mother's Day</option>
          <option value="Father's Day">Father's Day</option>
          <option value="Other">Other</option>
        </select>
        {errors.occasion && <span className="text-danger">This field is required</span>}
      </div>

      <div className="form-group">
        <label htmlFor="date">Date</label>
        <input
          {...register('date', { required: true })}
          type="date"
          className="form-control"
          id="date"
        />
        {errors.date && <span className="text-danger">This field is required</span>}
      </div>

      <div className="form-group">
        <label htmlFor="line1">Address Line 1</label>
        <input {...register('line1')} className="form-control" id="line1" placeholder="Address Line 1" ref={line1Ref}/>
        {errors.line1 && <span className="text-danger">This field is required</span>}
      </div>

      <div className="form-group">
        <label htmlFor="line2">Address Line 2</label>
        <input {...register('line2')} className="form-control" id="line2" placeholder="Address Line 2" />
      </div>

      <div className="form-group">
        <label htmlFor="city">City</label>
        <input {...register('city', { required: true })} className="form-control" id="city" placeholder="City" />
        {errors.city && <span className="text-danger">This field is required</span>}
      </div>

      <div className="form-group">
        <label htmlFor="state">State</label>
        <input {...register('state', { required: true })} className="form-control" id="state" placeholder="State" />
        {errors.state && <span className="text-danger">This field is required</span>}
      </div>

      <div className="form-group">
        <label htmlFor="zip">Zip</label>
        <input {...register('zip', { required: true })} className="form-control" id="zip" placeholder="Zip" />
        {errors.zip && <span className="text-danger">This field is required</span>}
      </div>

      <button type="submit" className="btn btn-primary">Submit</button>
    </form>
    
  </div>
);
}

export default Contacts;
